.home_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4em;
  background-color: #000;
  height: 89%;
}

.home_container h2 {
  font-size: 1.5em;
  margin-bottom: .5em;
}

.home_container h2 span {
  color: #FFBB33;
  padding: 0 0.2em;
  background-color: #222;
}

.home_container p {
  margin-bottom: 1.5em;
  color: #7b7b7b;
}

.home_container img {
  width: 350px;
  margin: 2em 0;
}

