* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: 'Verdana', sans-serif; */
  font-family: 'Nunito', sans-serif;
}

html, body, #root {
  color: antiquewhite;
  background-color: #000;
  height: 100%
}
