.navbar {
  display: flex;
  justify-content: space-between;
  /* background-color: #313a46; */
  background-color: #000;
  padding: 0.5em;
}

.list {
  display: flex;
  list-style: none;
  align-items: center;
}

.item {
  margin-right: 1em;
}

.item a {
  color: #FFF;
  text-decoration: none;
}

.item a:hover {
  color: #FFBB33;
}

.img {
  position: relative;
  width: 170px;
  height: 60px;
  top: -15px;
}

.btn {
	color: lightgray;
	width: 120px;
	/* margin: 10px auto 30px 90px; */
  margin: 15px;
	border-radius: 5px;
	border: 1px solid green;
	font-size: 15px;
	padding: 10px 10px;
	background-color: green;
	cursor: pointer;
}

.btn:hover, .btn:focus {
	background-color: lightgreen;
	color: green;
	outline: 0;
}
