.navbar {
  display: flex;
  justify-content: right;
  /* background-color: #37404a; */
  background-color: #000;
  padding: 1em 1em 0 1em;
  font-size: 10pt;
}

.list {
  display: flex;
  list-style: none;
  align-items: flex-end;
}

.item {
  margin-right: 1em;
}

.item a {
  color: #00bc00;
  text-decoration: none;
}

.item a:hover {
  color: #FFBB33;
}

.img {
  position: absolute;
  padding: 1em 1em 0 auto;
  width: 170px;
  height: 60px;
}

.fawhatsapp {
  color: #00bc00
}